/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
  // Initialize variables in relation with checkbox conditionals
  var canSubmit = false;
  var canSubmit2 = false;
  var canSubmit3 = false;

  // Set initial text from ACF
  $("#newsletter-text").text($(".signup").attr("data-newsletter-text"));
  $("#contactme-text").text($(".signup").attr("data-contactme-text"));
  $("#contactpage-text").text($(".contactpage").attr("data-contactpage-text"));

  $("#newsletter-submit").click(function(e) {
    if(!canSubmit) {
      e.preventDefault();
      $("#newsletter-text").css("color", "red");
    } else {
      $("#newsletter-text").css("color", "black");
    }
  });
  $("#newsletter-checkbox").click(function() {
    if($("#newsletter-checkbox").prop("checked")) {
      canSubmit = true;
    } else {
      canSubmit = false;
    }
  });

  $(".contactreq .submit-container input").click(function(e) {
    if(!canSubmit2) {
      e.preventDefault();
      $("#contactme-text").css("color", "red");
    } else {
      $("#contactme-text").css("color", "black");
    }
  });
  $("#contactme-checkbox").click(function() {
    if($("#contactme-checkbox").prop("checked")) {
      canSubmit2 = true;
    } else {
      canSubmit2 = false;
    }
  });

  $(".contactpage .submit-container input").click(function(e) {
    if(!canSubmit3) {
      e.preventDefault();
      $("#contactpage-text").css("color", "red");
    } else {
      $("#contactpage-text").css("color", "black");
    }
  });
  $("#contactpage-checkbox").click(function() {
    if($("#contactpage-checkbox").prop("checked")) {
      canSubmit3 = true;
    } else {
      canSubmit3 = false;
    }
  });

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var owl = $(".product-slider .owl-carousel");

        owl.owlCarousel({

          // Most important owl features
          items : 3,
          //itemsCustom : [[602,2]],
          itemsDesktop : [1199,3],
          itemsDesktopSmall : [980,2],
          itemsTablet: [768,3],
          itemsTabletSmall: [606,2],
          itemsMobile : [479,1],
          singleItem : false,
          itemsScaleUp : false,
          autoWidth:true,
          margin:10,
          afterAction : afterAction,
          
          // Navigation
          navigation: true,
          navigationText: [
            "<i class='fa fa-angle-left'></i></i>",
            "<i class='fa fa-angle-right'></i></i>"
            ],

          //Pagination
          pagination : false,

        });

        function afterAction(){
          $(".owl-wrapper .active").removeClass('active');
          $(".owl-wrapper .first").removeClass('first');
          
          if(this.owl.currentItem == 0){
            $('.owl-wrapper').children().first().addClass('first');
          }
          else{
            this.$owlItems.eq(this.currentItem).addClass('active');
          }
        }
        //End of carousel


        //Change order.
        resize();
        $(window).resize(function() {
          resize();
        });

        function resize(){
          if($( window ).width() < 768){
            $(".product-slider .slide").after($(".product-slider .current"));
            $(".product-slider .current").addClass('row');
            $(".product-slider .current").removeClass('col-sm-3');
          }
          else{
            $(".product-slider .slide").before($(".product-slider .current"));
            $(".product-slider .current").addClass('col-sm-3');
            $(".product-slider .current").removeClass('row');
          }
        }


        //Give navigation link products an active class
        $('body.single-post .nav-primary #menu-mainmenu > li:first-child').addClass('active'); 

        $('.nav a.dropdown-toggle').each(function(){

            //Clona first-child i submeny (Borde alltid finnas åtminstone en eftersom det är en dropdown)
            $(this).parent().addClass('dropdown');

        });

        $('.hidden-sm .nav a.dropdown-toggle').each(function(){

            //Clona first-child i submeny (Borde alltid finnas åtminstone en eftersom det är en dropdown)
            $(this).parent().addClass('dropdown');

            
            $cloned = $('ul li:first-child',$(this).parent()).clone();
            $('a',$cloned).attr('href',$(this).attr('href')).html($(this).text());


            //Ta klasserna från huvudmenyval men exkludera dropdownklassen
            var $classes = $(this).parent().attr('class').replace('dropdown','');

            //Om det finns en annan submeny som är aktiv, ta bort även active classen som kommer från huvudmenyval då en submeny är active
            $classes = $(this).parent().find('ul li.active').length > 0 ? $classes.replace('active', ''):$classes;

            //Modifiera classer,href och text på clonen för att spegla huvudmenyval
            $cloned.attr('class',$classes).find('a').attr('href',$(this).attr('href')).text($(this).text());

            //Prependa den så den hamnar först i submenyn
            $('ul',$(this).parent()).prepend($cloned);
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'order_brochure':{
      init: function() {

        $('.product-list .col-sm-8 .col-xs-4 img').on('click', function(){
          if($(this).siblings('.prod-checkbox').find('input').is(':checked')){
            $(this).siblings('.prod-checkbox').find('input').prop('checked', false);
          }
          else{
            $(this).siblings('.prod-checkbox').find('input').prop('checked', true);
          }
          checkboxChanges();
        });

        $('.product-list .col-sm-8 .col-xs-4 span').on('click', function(){
          if($(this).siblings('input').is(':checked')){
            $(this).siblings('input').prop('checked', false);
          }
          else{
            $(this).siblings('input').prop('checked', true);
          }
          checkboxChanges();
        });

        $('body').on('change', '.prod-checkbox input', function(){
          checkboxChanges();
        });

        function checkboxChanges() {
          $(".prodreq-form .hidden textarea").val('');
          $('.product-list .col-sm-8 .col-xs-4').each(function(index, value){
            if($('.prod-checkbox',this).find('input').is('input:checked')){
              value = $(".prodreq-form .hidden textarea").val() + $('.prod-checkbox',this).find('input').attr('name')+ "\r\n";
              $(".prodreq-form .hidden textarea").val(value);
            }
          });
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
