$ = jQuery;

$(function(){
	if (typeof(mapLat) === 'undefined' || typeof(mapLong) === 'undefined') {
		return;
	}

	if ( $(window).width() < 1024) {
		   mapOptions = {
			    zoom: 14,
			    streetViewControl: false,
		            disableDefaultUI:true,
		            draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true,
			    center: new google.maps.LatLng(mapLat,mapLong),
			    mapTypeId: google.maps.MapTypeId.ROADMAP
			  };
	}
	else {
	   	  mapOptions = {
	    	    scrollwheel: false,
		    streetViewControl: false,
	            disableDefaultUI:true,
		    zoom: 14,
		    zoomControl: true,
		    center: new google.maps.LatLng(mapLat,mapLong),
		    mapTypeId: google.maps.MapTypeId.ROADMAP
		  };
	}

	initialize();	
	
	//Set Tabclicks
	$('.mapTabs li a').each(function(){
		$(this).click(function(){
			beachMarker.setPosition(new google.maps.LatLng( $(this).attr('data-lat'), $(this).attr('data-long')));
			responsiveMap.panTo(new google.maps.LatLng( $(this).attr('data-lat'), $(this).attr('data-long')));
		});
	});
	
});



function initialize() {

	 var styles = [ 
	  {
            stylers: [
              //{ saturation: -0 },
              //{ gamma: 0.5 },
              //{ weight: 0.5 }
            ]
          },
          {
	    "featureType": "water",
	    "stylers": [
	      //{ "color": "#6e9fd7" }
              //{ saturation: -0 },
              { visibility: 'simplified' }
              //{ gamma: 0.5 },
              //{ weight: 0.5 }
	    ]
	  },{
	  }
	];

	 var styledMap = new google.maps.StyledMapType(styles,{name: "Styled Map"});

    $mapCanvas = $('<div />', { 'id':'map-canvas', 'style':'height:310px;' });
    $mapCanvas.prependTo('.mainText .tab-content .map-canvas');

    //responsiveMap = new google.maps.Map($mapCanvas, mapOptions);


	 responsiveMap = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

	 responsiveMap.mapTypes.set('map_style', styledMap);
   	 responsiveMap.setMapTypeId('map_style');
	 var image = baseUrl + '/assets/images/map-marker.png';
 	 var myLatLng = new google.maps.LatLng(mapLat,mapLong);
 	 beachMarker = new google.maps.Marker({position: myLatLng,map: responsiveMap,icon: image});
 	 
 	 
 	 //Start centercheck
 	setMapcenter();
 	 
}

var mapTimer;
function setMapcenter(){
	$(window).resize(function() {
		window.clearTimeout(mapTimer);
		mapTimer = window.setTimeout(function(){
			responsiveMap.panTo(beachMarker.getPosition());
		},200);	
	});
}




